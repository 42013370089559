import React from 'react'
import ServicesBanner from './Services-banner/ServicesBanner'
import ItServices from './IT-services/ItServices'

const Services = () => {
  return (
    <div>
    <ServicesBanner/>
    <ItServices/>
    </div>
  )
}

export default Services