import './App.css';
import React from "react"; 
import  Route from './Routes/routes'
function App() {
  return (
  <div>
    <Route/>
  </div>
  );
}

export default App;
